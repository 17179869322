/*eslint-disable camelcase*/
import React, { useEffect, useState, useContext } from 'react';
import styles from './arcade.module.scss';
// ad components
import { TakeoverContext } from '../contexts';
import Ads from '../components/Ads/Ads';
import AdUnit from '../components/Ads/AdUnit';
import { AdInFeed } from '../components/Ads/units/AdInFeed';
// apis
import { getSiteHeader } from '../upstreams/bf-header-ui';
//other components
import { getConfig } from '../utils/config';
import { useTrackingContext } from '../hooks';
//import Cookies from 'js-cookie';
import ABeagleProvider from '../components/ABeagleProvider';
import ConsentManagement from '../components/ConsentManagement';
import HomepageSiteHeader from '../components/SiteHeader';
import Header from '../components/Arcade/Header';
import DailyGames from '../components/Arcade/DailyGames';
import MoreFun from '../components/Arcade/MoreFun';
import NewInArcade from '../components/Arcade/NewInArcade';
import { CLUSTER } from '../constants';

import CustomHead from './_head';
import { getPageByName } from '../upstreams/feed-api';
import { getFeedPageSurrogateKeys } from '../utils/surrogate-keys';
import { getThemeStyles, normalizeZones } from'../utils/data';
import { MAX_AGE_SHORT } from '../constants/feeds';
import setResponseHeaders from '@buzzfeed/headers';
import { resolveAuthorization } from '../utils/auth';

import CustomWrapper from '../components/CustomWrapper';
import ContentComponent from '../components/FeedContent/ContentComponent';
import { ArcadeCreate } from '../icons/ArcadeCreate';
import { ArcadeLatest } from '../icons/ArcadeLatest';
import { ArcadeBeta } from '../icons/ArcadeBeta';
import { ArcadeChat } from '../icons/ArcadeChat';
import { ArcadeShowdown } from '../icons/ArcadeShowdown';
import { getPageMeta } from '../utils/meta';

export const arcadeCategories = [
  // Note: the filter id should map to the laser tag tag_name value
  { id: 'latest', label: 'Latest', icon: <ArcadeLatest/>, iconSmall: <ArcadeLatest className={styles.smallIcon}/>, count: 0 },
  { id: 'create_with_ai', label: 'Create with Ai', icon: <ArcadeCreate/>, iconSmall: <ArcadeCreate className={styles.smallIcon}/>, count: 0  },
  { id: 'beta_games', label: 'Beta Games', icon: <ArcadeBeta/>, iconSmall: <ArcadeBeta className={styles.smallIcon}/>, count: 0  },
  { id: 'chat_games', label: 'Chat Games', icon: <ArcadeChat/>, iconSmall: <ArcadeChat className={styles.smallIcon}/>, count: 0  },
  { id: 'showdown', label: 'Showdown', icon: <ArcadeShowdown/>, iconSmall: <ArcadeShowdown className={styles.smallIcon}/>, count: 0  },
];

export function ArcadePage({ header, pageConfig, tracking, metadata = {}, zones = {} }) {
  const {
    pageInfo,
    trackContentAction,
  } = useTrackingContext();

  const topicName = 'arcade';
  const takeover = useContext(TakeoverContext);
  const themeCss = getThemeStyles(metadata?.theme);
  const inlineCss = takeover?.inlineCss ? takeover.inlineCss : '' + themeCss;
  const { page_edition } = pageInfo;

  const [filter, setFilter] = useState(arcadeCategories[0]);
  const [moreFun, setMoreFun] = useState([]);
  const [arcadeCategoriesWithCount, setArcadeCategoriesWithCount] = useState([...arcadeCategories]);

  const {
    daily_games: dailyGames,
    featured_carousel: featuredCarousel,
    featured_widget: featuredWidget,
    games_library_1: gamesLibrary
  } = zones;

  const hasWidget = !!(featuredWidget?.items.length);
  const moreFunSlice = (hasWidget) ? 4 : 0;

  const getTags = (content) => {
    const laserTags = content.laserTags?.bf_content_description?.topic || [];
    return laserTags.map((tag) => tag?.tag_name);
  };

  const mapProps = (item) => {
    const nextItem = JSON.parse(JSON.stringify(item));
    const tags = getTags(nextItem.content);
    const defaultCategory = arcadeCategories[0];
    const category = arcadeCategories.find(({ id }) => tags.includes(id)) || defaultCategory;
    nextItem.content.category = category;
    nextItem.content.isPopular = false;
    nextItem.content.popularLabel = <>{category?.iconSmall} {category?.label}</>;
    return nextItem;
  };

  useEffect(() => {
    const filterCategory = (item) => filter.id === 'latest' || item.content?.category?.id === filter.id;
    if (gamesLibrary?.items) {
      setMoreFun(gamesLibrary?.items.map(mapProps).filter(filterCategory));
      const nextCategoriesWithCount = [...arcadeCategoriesWithCount];
      nextCategoriesWithCount.forEach(({ id }, index) => {
        const allItems = gamesLibrary?.items;
        const count = (id === 'latest') ? allItems.length : allItems.filter(item => {
          return getTags(item.content).includes(id);
        }).length;
        nextCategoriesWithCount[index].count = count;
      });
      setArcadeCategoriesWithCount(nextCategoriesWithCount);
    }
  }, [filter]);

  useEffect(() => {
    const handleReloadWidgetClick = () => {
      trackContentAction({
        ...tracking,
        unit_name: 'more_fun',
        subunit_type: 'component',
        subunit_name: 'package',
        item_type: 'button',
        item_name: 'widget',
        position_in_subunit: 1,
        action_type: 'reload',
        action_value: 'widget_refresh',
      });
    };

    const externalElement = document.getElementById('reloadWidget');
    if (externalElement) {
      externalElement.addEventListener('click', handleReloadWidgetClick);
    }
    // Cleanup function to be executed when the component unmounts
    return () => {
      externalElement?.removeEventListener('click', handleReloadWidgetClick);
    };

  }, []);

    return (
    <>
      <CustomWrapper>
        <Ads edition={page_edition} pagePath={topicName} pageName="topic">
          <AdUnit type="toolbar" />
          <AdUnit className="Ad--topic" type="awareness" />
          <HomepageSiteHeader {...header} communityPage="false" />
            <CustomHead pageConfig={pageConfig} page="arcade" inlineCss={inlineCss}/>
          <ABeagleProvider>
            <ConsentManagement isCommunity={false} />
          </ABeagleProvider>
            <main className={styles.pageContainer} style={takeover?.style}>
            <Header />
            <div className={styles.arcadeWrapper}>

              <DailyGames
                items={dailyGames?.items}
                trackingData={{
                  ...tracking,
                  unit_name: 'daily_games',
                  item_type: 'button',
                  target_content_type: 'game',
                }}
              />

              <div className="p-1">&nbsp;</div>
              <AdUnit className={styles.adStory} type="story1" />

              <NewInArcade
                slides={featuredCarousel?.items}
                trackingData={{
                  ...tracking,
                  unit_name: 'new_in_the_arcade',
                  item_type: 'card',
                }}
              />

              {!!(featuredWidget?.items.length + gamesLibrary?.items.length) && (
              <MoreFun filter={filter} onFilterChange={setFilter} arcadeCategories={arcadeCategoriesWithCount}>
                {hasWidget ?
                <div className={styles.flexWidgetWRapper}>
                  <div className={`${styles.content} ${styles.content66}`}>
                    {moreFun.slice(0,4).map((item, index) => (
                      <div className={styles.contentWrapper} key={item.id || index}>
                        <ContentComponent
                          className="gamePostCard"
                          item={{
                            ...item,
                            content: {
                              ...item.content,
                              title: (<>
                                <div>{item.content.title}</div>
                                {item.content.description.replace(/<\/?[^>]+(>|$)/g, '')}
                              </>)
                            }
                          }}
                          popularLabel={item.content.popularLabel}
                          isTrackable={true}
                          trackingData={{
                            ...tracking,
                            unit_name: `more_fun-${filter?.id}`,
                            position_in_unit: (index+1),
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.widget33}>
                    <ContentComponent
                      className={''}
                      item={featuredWidget?.items[0]}
                      trackingData={{
                        ...tracking,
                        unit_name: 'more_fun',
                        subunit_type: 'package',
                        subunit_name: 'featured_widget',
                      }}/>
                  </div>
                </div> : <div className={styles.noWidget}></div>
                }
                  <div className={styles.content}>
                    {moreFun.slice(moreFunSlice).map((item, index) => (
                      <React.Fragment key={item.id || `ad-${index}`}>
                        {index % 6 === 0 && (!hasWidget ? (index !== 0) : 1) &&
                          <AdInFeed
                            className={styles.adStory}
                            type={`story${index+moreFunSlice+1}`}
                          />
                        }
                        <div className={styles.contentWrapper} key={item.id || index}>
                          <ContentComponent
                            className="gamePostCard"
                            item={{
                              ...item,
                              content: {
                                ...item.content,
                                title: (<>
                                  <div>{item.content.title}</div>
                                  {item.content.description.replace(/<\/?[^>]+(>|$)/g, '')}
                                </>)
                              }
                            }}
                            popularLabel={item.content.popularLabel}
                            isTrackable={true}
                            trackingData={{
                              ...tracking,
                              unit_name: `more_fun-${filter?.id}`,
                              position_in_unit: (index + moreFunSlice + 1),
                            }}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
              </MoreFun>
              )}

            </div>
          </main>
      </Ads>
    </CustomWrapper>
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  const edition = 'en-us';
  const staticProps = {
    namespacesRequired: ['common'],
  };
  const skipCache = CLUSTER !== 'prod';

  try {
    const [
      header, // Fetch Error --> 500
      feedData,
      // eslint-disable-next-line no-undef
    ] = await Promise.all([
      getSiteHeader({ edition }),
      getPageByName({
        name: `custom__bf__${edition}__games-hub`,
        skipCache,
        tags: ['type: page', 'upstream:feed_api', 'page_type:custom']
      })
    ]);

    if (!feedData || (feedData instanceof Object && !Object.keys(feedData).length)) {
      return {
        notFound: true,
      };
    }

    resolveAuthorization({ req, res }, feedData.authorization);

    const customKeys = getFeedPageSurrogateKeys({ feedPage: feedData, cluster: CLUSTER });
    const zones = normalizeZones(feedData.zones, { originTag: '' });

    setResponseHeaders({
      req,
      res,
      customKeys,
      /**
       * A max-age is defined only if served on prod and there is no basic auth required. Otherwise
       * max-age will be `0`.
       * @todo make longer once full event driven purging is set up
       */
      maxAge: CLUSTER === 'prod' && !feedData.authorization ? MAX_AGE_SHORT : 0,
      edition: 'en-us',
    });

    const meta = getPageMeta(feedData);

    // Update share image
    const metaImageUrl = 'https://img.buzzfeed.com/pubhub-api-prod-us-east-1/assets/491d8fa1aa1c47928e99e599f7e04997/buzzfeed-games-hub-share.png';
    meta.image = metaImageUrl;
    meta.fb.image = metaImageUrl;
    meta.twitter.image = metaImageUrl;

    return {
      props: {
        ...staticProps,
        header,
        metadata: feedData.metadata,
        pageConfig: getConfig({ page: 'custom', meta, edition, path: req.path }),
        zones,
        tracking: {
          unit_type: 'feed',
          unit_name: 'main',
          context_page_type: 'feed',
          context_page_id: 6,
          destination: 'buzzfeed',
          page_edition: 'en-us',
          position_in_unit: 0,
          position_in_subunit: 0
        },
      },
    };
  } catch (error) {
    console.error('getServerSideProps failed to complete execution on community guidelines page');
    console.error(error);
    throw error;
  }
}

export default ArcadePage;
