import { useEffect, useState } from 'react';
import styles from './header.module.scss';

import { ArcadeLogo } from '../../../icons/ArcadeLogo';
import SubscriptionButton from '@buzzfeed/react-components/lib/components/SubscriptionButton';
import { site_captcha_key } from '../../../constants';
import { useMediaQuery, useTrackingContext } from '../../../hooks';
import cookies from '../../../../@buzzfeed/bf-utils/lib/cookies';

const Header = () => {
  const screenSize = useMediaQuery();
  const [isUserAuth, setUserAuth] = useState(false);
  const commonTrackingData = {
    unit_name: 'header',
    position_in_unit: 0,
  };

  useEffect(() => {
    setUserAuth(!!cookies.get('bf2-b_info'));
  }, []);

  const { trackImpression, trackContentAction, trackInternalLink } = useTrackingContext();

  const subscriptionButtonTrackingData = {
    ...commonTrackingData,
    item_type: 'button',
  };

  const subscriptionButton = (<SubscriptionButton
    topic="Arcade"
    description="Be notified on the launch of new games, AI image generators, filters, and experiments — no more than once a week!"
    descriptionLoggedOut="Be notified on the launch of new games, AI image generators, filters, and experiments — no more than once a week!"
    hideTopic={screenSize.isMobile}
    subscriptions={[
      {
        subscriptionId: 'buzzfeed_email_arcade-highlights-weekly',
        title: 'Arcade Highlights',
        description: '',
        isSubscribed: true,
      },
    ]}
    track={{
      commonTrackingData: subscriptionButtonTrackingData,
      impression: trackImpression,
      contentAction: trackContentAction,
      internalLink: trackInternalLink,
    }}
    isSignedIn={isUserAuth}
    siteCaptchaKey={site_captcha_key}
  />);

  return (
    <>
      <div className={styles.headerWrapper}>
        <ArcadeLogo className={styles.arcadeLogo} />

        {subscriptionButton}
      </div>
    </>
  );
};

export default Header;
