export const ArcadeCreate = ({ className = '' }) => (
  <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_850_837)">
        <path d="M44.8397 10.4919L47.081 2.82452C47.201 2.42097 46.8193 2.05559 46.4212 2.18102L38.8246 4.65685C38.6719 4.70593 38.5029 4.68411 38.372 4.59141L31.7734 0.0923801C31.4299 -0.142115 30.9609 0.103287 30.9609 0.523196L30.9718 8.51238C30.9718 8.67598 30.8954 8.82867 30.77 8.92683L24.4496 13.813C24.1169 14.0694 24.2096 14.5929 24.6077 14.7183L29.6902 16.3598C30.0665 16.4797 30.1756 16.9651 29.8866 17.2323L0.13845 44.7282C-0.0415112 44.8972 -0.0469644 45.1808 0.127543 45.3553L4.09214 49.3199C4.26665 49.4944 4.55022 49.489 4.71928 49.309L32.4006 19.37C32.6678 19.0865 33.1368 19.1846 33.2676 19.55L35.2254 25.0197C35.3672 25.4124 35.8907 25.4887 36.1361 25.1506L40.8206 18.6829C40.9133 18.552 41.066 18.4702 41.2296 18.4702L49.2133 18.2303C49.6332 18.2194 49.8677 17.7395 49.6168 17.4014L44.916 10.9446C44.8233 10.8137 44.7961 10.6446 44.8397 10.4919Z" fill="currentColor"/>
        <path d="M14.5789 5.94962L17.5674 7.62381C17.6165 7.65107 17.6492 7.68925 17.6656 7.73833L18.8653 11.4084C18.9307 11.6048 19.2361 11.6048 19.3016 11.4084L20.5013 7.73833C20.5177 7.68925 20.5504 7.65107 20.5995 7.62381L23.5879 5.94962C23.7297 5.86782 23.7297 5.68786 23.5879 5.60606L20.5995 3.93188C20.5504 3.90461 20.5177 3.86644 20.5013 3.81736L19.3016 0.147241C19.2361 -0.0490803 18.9307 -0.0490803 18.8653 0.147241L17.6656 3.81736C17.6492 3.86644 17.6165 3.90461 17.5674 3.93188L14.5789 5.60606C14.4372 5.68786 14.4372 5.87327 14.5789 5.94962Z" fill="currentColor"/>
        <path d="M8.92404 16.9764L11.1218 18.2089C11.1545 18.2307 11.1817 18.2579 11.1926 18.2907L12.0761 20.9901C12.1252 21.1319 12.3488 21.1319 12.3978 20.9901L13.2813 18.2907C13.2922 18.2579 13.3195 18.2252 13.3522 18.2089L15.5499 16.9764C15.6535 16.9164 15.6535 16.7801 15.5499 16.7255L13.3522 15.4931C13.3195 15.4713 13.2922 15.444 13.2813 15.4113L12.3978 12.7119C12.3488 12.5701 12.1252 12.5701 12.0761 12.7119L11.1926 15.4113C11.1817 15.444 11.1545 15.4767 11.1218 15.4931L8.92404 16.7255C8.82043 16.7855 8.82043 16.9219 8.92404 16.9764Z" fill="currentColor"/>
        <path d="M35.8413 35.8287L32.8529 34.1545C32.8038 34.1272 32.7711 34.089 32.7547 34.04L31.555 30.3698C31.4896 30.1735 31.1842 30.1735 31.1187 30.3698L29.919 34.04C29.9026 34.089 29.8699 34.1272 29.8208 34.1545L26.8324 35.8287C26.6906 35.9105 26.6906 36.0904 26.8324 36.1722L29.8208 37.8464C29.8699 37.8737 29.9026 37.9118 29.919 37.9609L31.1187 41.631C31.1842 41.8274 31.4896 41.8274 31.555 41.631L32.7547 37.9609C32.7711 37.9118 32.8038 37.8737 32.8529 37.8464L35.8413 36.1722C35.9831 36.0904 35.9831 35.9105 35.8413 35.8287Z" fill="currentColor"/>
        <path d="M46.7374 28.652L44.5397 27.4195C44.507 27.3977 44.4797 27.3705 44.4688 27.3377L43.5853 24.6329C43.5363 24.4911 43.3127 24.4911 43.2636 24.6329L42.3801 27.3323C42.3692 27.365 42.342 27.3977 42.3092 27.4141L40.1061 28.652C40.0025 28.712 40.0025 28.8483 40.1061 28.9029L42.3038 30.1353C42.3365 30.1571 42.3638 30.1844 42.3747 30.2171L43.2581 32.9165C43.3072 33.0583 43.5308 33.0583 43.5799 32.9165L44.4633 30.2171C44.4742 30.1844 44.5015 30.1517 44.5342 30.1353L46.7319 28.9029C46.8464 28.8429 46.8464 28.7065 46.7374 28.652Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_850_837">
          <rect width="50" height="50" fill="none"/>
        </clipPath>
      </defs>
    </svg>

);
