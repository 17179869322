import styles from './morefun.module.scss';
import { useState } from 'react';
import { ArcadeMenu } from '../../../icons/ArcadeMenu';
import { useTrackingContext } from '../../../hooks';

const MoreFun = (props) => {
  const { trackContentAction } = useTrackingContext();
  const navigationFilters = [ ...props.arcadeCategories ];
  const [menuOpen, setMenuOpen] = useState(false);
  const [showOverlay, setOverlay] = useState(false);
  const handleClickTracking = (position, value) => {
    trackContentAction({
      context_page_type: 'feed',
      context_page_id: 6,
      unit_type: 'feed',
      unit_name: `more_fun-${props.filter?.id}`,
      position_in_unit: position,
      position_in_subunit: 0,
      subunit_type: 'component',
      subunit_name: 'tabs',
      item_type: 'button',
      item_name: value,
      action_type: 'select',
      action_value: value,
      target_content_type: 'game',
    });
  };

  return (
    <>
      {showOverlay &&  <div className={styles.overlay} onClick={() => { setOverlay(false); setMenuOpen(false); }}>&nbsp;</div>}

      <div className={styles.zoneWrapper}>

        <div className={styles.mobileMenu} onClick={() => {setMenuOpen(!menuOpen); setOverlay(!menuOpen);}}>
          <ArcadeMenu/>
        </div>

        <nav className={`${styles.navigationFilters} ${(menuOpen) ? styles.menuOpen : ''}`}>
          <ul>
            {navigationFilters.map((item, index) => (
              <li className={`${(item.id === props.filter.id) ? styles.active : ''} ${(item.count <= 0) ? styles.disabled : ''}`} key={item.id} onClick={() => {
                handleClickTracking((index+1), item.id);
                props.onFilterChange(item);
              }}>
                {item.icon}
                {item.label}
              </li>
            ))}
          </ul>
        </nav>

        <h2 className={styles.arcadeHeader}>More Fun</h2>

        <div className={styles.clearFix}></div>

        {props.children}
      </div>
    </>
  );
};

export default MoreFun;
