import React, { useState, useEffect } from 'react';
import styles from './dailygames.module.scss';

import { useBreakpoint } from '@buzzfeed/react-components';
import { useTrackingContext, useViewImpression } from '../../../hooks';

import CountdownTimer from './CountdownTimer';
import { StreakIcon } from '../../../icons/StreakIcon';

const snakeCase = string => {
  return string.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

const Streak = ({ days }) => {
  if (!days) {
    return <></>;
  }

  return (
    <span className={styles.streak}><StreakIcon /> {days}</span>
  )
}

const Game = ({ game, index, trackingData }) => {
  const { trackInternalLink } = useTrackingContext();
  const isMobile = useBreakpoint('(max-width: 40rem)');
  const [hoveredGameId, setHoveredGameId] = useState(null);
  const [pyramidSchemeData, setPyramidSchemeData] = useState(null);
  const [dailyTriviaData, setDailyTriviaData] = useState(null);

  const { object_type: objectType, trackingData: algorithmTrackingData } = game;
  const contentObjectId = game.id;
  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
  };
  const sharedTrackingData = {
    ...algorithmTrackingData,
    ...subunitTrackingData,
    ...trackingData,
    position_in_unit: index,
    item_name: snakeCase(game.content.title),
    target_content_id: snakeCase(game.content.title),
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let data = localStorage.getItem('pyramidScheme');
      setPyramidSchemeData(data ? JSON.parse(data) : null);
      data = localStorage.getItem('dailyTrivia');
      setDailyTriviaData(data ? JSON.parse(data) : null);
    }
  }, []);

  const lastPlayedDateOlderThanYesterday = (dateString) => {
    if (!dateString) {
      return true;
    }

    const inputDate = new Date(dateString);
    const yesterday = new Date();

    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    inputDate.setHours(0, 0, 0, 0);

    return inputDate < yesterday;
  }

  const streakByGameName = {
    'Pyramid Scheme': !lastPlayedDateOlderThanYesterday(pyramidSchemeData?.lastSeen)
      ? pyramidSchemeData?.currentStreak : 0,
    'Daily Trivia': !lastPlayedDateOlderThanYesterday(dailyTriviaData?.lastPlayed)
      ? dailyTriviaData?.currentStreak : 0,
  };

  const { setObservable } = useViewImpression({ trackingData: sharedTrackingData });

  const handleMouseEnter = (id) => {
    setHoveredGameId(id);
  };

  const handleMouseLeave = () => {
    setHoveredGameId(null);
  };

  return (<div className={styles.game}>
    <a
      href={game.content.url} className={styles.imgWrapper}
      onClick={() => trackInternalLink(sharedTrackingData)}
    >
      <img
        className={hoveredGameId === game.id ? styles.bobAnimation : ''}
        src={game.content.title === 'Lil Snack Games'
          ? 'https://www.lilsnack.co/assets/buzzfeed-thumbnail-small.png'
          : game.content.images.square}
        alt={game.content.images.square_alt_text}
      />
    </a>
    <div className={styles.gameTextWrapper} onClick={(e) => {
      if (isMobile) {
        e.preventDefault();
        trackInternalLink(sharedTrackingData);
        window.location.href = game.content.url;
      }
    }}>
      <h2 className={styles.gameTitle}>
        {game.content.title}
        {(streakByGameName[game.content.title] > 0)
          && <div>&nbsp;<Streak days={streakByGameName[game.content.title]} /></div>}
      </h2>
      <p>{game.content.description}</p>
      {(!isMobile && game.content.cta) && <a
          ref={obvEl => setObservable(obvEl)}
          onClick={() => trackInternalLink(sharedTrackingData)}
          href={game.content.url}
          className={styles.playNowButton}
          onMouseEnter={() => handleMouseEnter(game.id)}
          onMouseLeave={handleMouseLeave}
        >
          {game.content.cta}
        </a>}
    </div>
    {(isMobile && game.content.cta) && <button
        ref={obvEl => setObservable(obvEl)}
        onClick={() => {
          trackInternalLink(sharedTrackingData);
          window.location.href = game.content.url;
        }}
        className={styles.playNowButton}
        onMouseEnter={() => handleMouseEnter(game.id)}
        onMouseLeave={handleMouseLeave}
      >
      {game.content.cta}
      </button>}
  </div>);
};

const DailyGames = ({ items, trackingData = {} }) => {
  const isTablet = useBreakpoint('(max-width: 52rem)');

  if (!items.length) {
    return <></>;
  }

  return (
    <div className={styles.zoneWrapper}>
      <div className={styles.header}>
        <h2 className={styles.arcadeHeader}>Daily Games</h2>
        {!isTablet && <CountdownTimer />}
      </div>
      <div className={styles.games}>
        {items.map((game, index) => (
          <Game key={game.id || index} game={game} index={index} trackingData={trackingData} />
        ))}
      </div>
      {isTablet && <CountdownTimer />}
    </div>
  );
};

export default DailyGames;
