export const ArcadeLogo = ({ className = '' }) => (
  <svg className={className} width="254" height="74" viewBox="0 0 254 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M166.751 43.6482C166.038 37.0301 160.206 31.9988 153.243 31.9988H145.314C138.352 31.9988 132.52 37.0301 131.807 43.6482L128.728 72.2628C128.674 72.7738 129.091 73.2176 129.628 73.2176H144.491C144.965 73.2176 145.359 72.8679 145.394 72.4175L145.854 59.4279C145.87 58.9617 146.271 58.592 146.759 58.592H151.801C152.289 58.592 152.69 58.9617 152.706 59.4279L153.166 72.4175C153.201 72.8679 153.595 73.2176 154.069 73.2176H168.932C169.469 73.2176 169.886 72.7738 169.832 72.2628L166.753 43.6482H166.751ZM154.427 46.64L151.011 48.4688C150.957 48.4979 150.917 48.5428 150.9 48.5943L149.528 52.5969C149.456 52.8098 149.104 52.8098 149.031 52.5969L147.659 48.5943C147.641 48.5428 147.603 48.4979 147.549 48.4688L144.132 46.64C143.971 46.5526 143.971 46.3531 144.132 46.2657L147.549 44.4371C147.603 44.4079 147.643 44.3631 147.659 44.3115L149.031 40.3088C149.104 40.0959 149.456 40.0959 149.528 40.3088L150.9 44.3115C150.919 44.3631 150.957 44.4079 151.011 44.4371L154.427 46.2657C154.589 46.3531 154.589 46.5526 154.427 46.64Z" fill="#FF4040"/>
    <path d="M84.2184 52.6082C86.2422 50.636 87.2552 47.8301 87.2552 44.1905C87.2552 41.4429 86.7605 39.1681 85.7685 37.3685C84.7765 35.5689 83.3671 34.2265 81.5473 33.3345C79.7229 32.4448 77.5161 31.9988 74.9248 31.9988H46.3664C45.8668 31.9988 45.4612 32.3865 45.4612 32.8638V72.3526C45.4612 72.8299 45.8668 73.2176 46.3664 73.2176H61.9307C62.4302 73.2176 62.8359 72.8299 62.8359 72.3526V59.4526C62.8359 58.9753 63.2416 58.5875 63.7411 58.5875H65.7368C66.1683 58.5875 66.5412 58.8789 66.6256 59.2845L67.8543 65.2078C68.8205 69.8648 73.0956 73.2198 78.0649 73.2198H86.3525C86.852 73.2198 87.2576 72.8321 87.2576 72.3548V59.4548C87.2576 58.9774 86.852 58.5898 86.3525 58.5898H79.4813C77.7366 58.5898 76.3225 57.2383 76.3225 55.5709C79.5634 55.5709 82.1946 54.5848 84.2184 52.6104V52.6082ZM62.8359 49.9435V42.9646C62.8359 42.4872 63.2416 42.0995 63.7411 42.0995H67.3361C67.8356 42.0995 68.2413 42.4872 68.2413 42.9646V49.9435C68.2413 50.4208 67.8356 50.8085 67.3361 50.8085H63.7411C63.2416 50.8085 62.8359 50.4208 62.8359 49.9435Z" fill="#FF4040"/>
    <path d="M100.135 34.2917C96.8945 35.8582 94.4157 38.18 92.6944 41.2571C90.9731 44.3342 90.1125 48.0768 90.1125 52.4896C90.1125 56.9024 90.9333 60.7056 92.5725 63.7805C94.2117 66.8575 96.6131 69.1994 99.7719 70.8063C102.931 72.4132 106.798 73.2155 111.375 73.2155H123.469C123.968 73.2155 124.374 72.8279 124.374 72.3505V56.8396C124.374 56.3623 123.968 55.9746 123.469 55.9746H108.634C108.135 55.9746 107.729 55.5868 107.729 55.1094V49.9885C107.729 49.5112 108.135 49.1234 108.634 49.1234H123.469C123.968 49.1234 124.374 48.7357 124.374 48.2583V32.8035C124.374 32.3262 123.968 31.9385 123.469 31.9385H111.798C107.262 31.9385 103.374 32.7229 100.135 34.2917Z" fill="#FF4040"/>
    <path d="M207.838 34.5513C205.167 32.8481 201.622 31.9988 197.208 31.9988H175.089C174.59 31.9988 174.184 32.3865 174.184 32.8638V72.3526C174.184 72.8299 174.59 73.2176 175.089 73.2176H196.903C201.439 73.2176 205.064 72.4332 207.777 70.8666C210.488 69.3001 212.423 67.0253 213.579 64.0446C214.733 61.0639 215.31 57.3302 215.31 52.839V52.7224C215.31 48.3881 214.733 44.7014 213.579 41.6624C212.423 38.6257 210.512 36.2546 207.838 34.5513ZM196.73 62.5364H192.464C191.965 62.5364 191.559 62.1487 191.559 61.6713V43.5451C191.559 43.0677 191.965 42.6799 192.464 42.6799H196.73C197.229 42.6799 197.635 43.0677 197.635 43.5451V61.6713C197.635 62.1487 197.229 62.5364 196.73 62.5364Z" fill="#FF4040"/>
    <path d="M253.157 42.3393V32.8638C253.157 32.3865 252.752 31.9988 252.252 31.9988H228.313C223.314 31.9988 219.261 35.8714 219.261 40.6495V64.5646C219.261 69.3427 223.314 73.2153 228.313 73.2153H252.252C252.752 73.2153 253.157 72.8276 253.157 72.3503V62.8188C253.157 62.3414 252.752 61.9536 252.252 61.9536H236.993C236.493 61.9536 236.087 61.566 236.087 61.0886V57.9981C236.087 57.5207 236.493 57.133 236.993 57.133H252.36C252.859 57.133 253.265 56.7453 253.265 56.268V48.7108C253.265 48.2335 252.859 47.8458 252.36 47.8458H236.993C236.493 47.8458 236.087 47.458 236.087 46.9806V44.0672C236.087 43.5898 236.493 43.2021 236.993 43.2021H252.252C252.752 43.2021 253.157 42.8144 253.157 42.3371V42.3393Z" fill="#FF4040"/>
    <path d="M38.028 43.6482C37.315 37.0301 31.4828 31.9988 24.5202 31.9988H16.5915C9.62893 31.9988 3.79679 37.0301 3.08388 43.6482L0.00469624 72.2628C-0.0492407 72.7738 0.368262 73.2176 0.905287 73.2176H15.7684C16.2421 73.2176 16.6361 72.8679 16.6713 72.4175L17.1309 59.4279C17.1473 58.9617 17.5483 58.592 18.0361 58.592H23.078C23.5658 58.592 23.9668 58.9617 23.9832 59.4279L24.4429 72.4175C24.4781 72.8679 24.872 73.2176 25.3457 73.2176H40.209C40.746 73.2176 41.1634 72.7738 41.1094 72.2628L38.0304 43.6482H38.028ZM25.7022 46.64L22.2854 48.4688C22.2315 48.4979 22.1916 48.5428 22.1751 48.5943L20.8034 52.5969C20.7307 52.8098 20.3789 52.8098 20.3062 52.5969L18.9343 48.5943C18.9155 48.5428 18.8779 48.4979 18.824 48.4688L15.4073 46.64C15.2455 46.5526 15.2455 46.3531 15.4073 46.2657L18.824 44.4371C18.8779 44.4079 18.9179 44.3631 18.9343 44.3115L20.3062 40.3088C20.3789 40.0959 20.7307 40.0959 20.8034 40.3088L22.1751 44.3115C22.1939 44.3631 22.2315 44.4079 22.2854 44.4371L25.7022 46.2657C25.864 46.3531 25.864 46.5526 25.7022 46.64Z" fill="#FF4040"/>
    <path d="M15.5286 12.0081C16.6801 12.0932 17.6837 12.5594 18.5373 13.4066C19.3909 14.2537 19.8177 15.4191 19.8177 16.9005C19.8177 18.7225 19.1869 20.1725 17.9229 21.2527C16.6589 22.333 14.8438 22.8731 12.4729 22.8731H0.906982V1.59131H12.44C14.4779 1.59131 16.1125 2.12018 17.3437 3.18023C18.5725 4.24029 19.1893 5.64774 19.1893 7.40479C19.1893 8.73826 18.8023 9.7983 18.0261 10.5804C17.2499 11.3648 16.4198 11.7974 15.5333 11.8826V12.0104L15.5286 12.0081ZM6.38977 10.3877H11.1761C11.9969 10.3877 12.6488 10.1703 13.1366 9.73554C13.6244 9.30076 13.8683 8.72485 13.8683 8.00545C13.8683 7.3488 13.629 6.81982 13.153 6.41641C12.6769 6.01301 12.0836 5.81356 11.3754 5.81356H6.38977V10.3877ZM11.5067 18.6463C12.372 18.6463 13.0404 18.4356 13.5188 18.0121C13.9949 17.5885 14.2341 17.0058 14.2341 16.264C14.2341 15.5648 13.9902 15.0089 13.5024 14.5965C13.0147 14.1842 12.3838 13.978 11.6076 13.978H6.38977V18.6463H11.5091H11.5067Z" fill="#481F6B"/>
    <path d="M38.8604 22.8707H33.5417V21.3466C32.2121 22.6801 30.505 23.348 28.4225 23.348C26.4714 23.348 24.9049 22.7384 23.7183 21.5215C22.534 20.3046 21.9407 18.6999 21.9407 16.7098V6.92725H27.2265V15.6296C27.2265 16.5619 27.4915 17.3194 28.0238 17.8998C28.5561 18.4825 29.2433 18.7738 30.0852 18.7738C31.1709 18.7738 32.0175 18.4085 32.6272 17.6779C33.2369 16.9473 33.5417 15.8313 33.5417 14.3275V6.92725H38.8604V22.8729V22.8707Z" fill="#481F6B"/>
    <path d="M55.2363 22.8708H41.1587V21.9138L47.779 10.9591H41.3229V6.92505H55.1542V7.88202L48.5364 18.8367H55.2363V22.8708Z" fill="#481F6B"/>
    <path d="M71.6113 22.8708H57.5337V21.9138L64.1539 10.9591H57.6979V6.92505H71.5292V7.88202L64.9113 18.8367H71.6113V22.8708Z" fill="#481F6B"/>
    <path d="M79.5208 10.6724H89.138V15.2465H79.5208V22.8709H74.0356V1.58911H90.0221V6.19459H79.5208V10.6746V10.6724Z" fill="#481F6B"/>
    <path d="M128.102 16.2011H115.87C116.184 18.18 117.73 19.2826 120.07 19.2826C121.911 19.2826 123.733 18.5924 125.096 17.5906L127.136 20.9679C125.269 22.4986 122.849 23.348 120.068 23.348C114.475 23.348 110.648 20.0916 110.648 14.8989C110.648 12.4427 111.501 10.419 113.206 8.83225C114.913 7.24328 117.005 6.44995 119.489 6.44995C121.972 6.44995 123.933 7.22305 125.572 8.76943C127.211 10.3158 128.053 12.3597 128.098 14.8989V16.2011H128.102ZM117.333 11.2436C116.67 11.6896 116.215 12.3038 115.971 13.0859H122.852C122.608 12.2589 122.188 11.6359 121.588 11.2124C120.99 10.7888 120.291 10.5781 119.493 10.5781C118.696 10.5781 117.997 10.8 117.333 11.2459V11.2436Z" fill="#481F6B"/>
    <path d="M108.651 16.2011H96.4193C96.7335 18.18 98.2789 19.2826 100.619 19.2826C102.46 19.2826 104.282 18.5924 105.645 17.5906L107.685 20.9679C105.818 22.4986 103.398 23.348 100.617 23.348C95.0239 23.348 91.1968 20.0916 91.1968 14.8989C91.1968 12.4427 92.0503 10.419 93.7552 8.83225C95.4624 7.24328 97.5542 6.44995 100.038 6.44995C102.521 6.44995 104.482 7.22305 106.121 8.76943C107.76 10.3158 108.602 12.3597 108.646 14.8989V16.2011H108.651ZM97.8826 11.2436C97.2189 11.6896 96.7639 12.3038 96.5201 13.0859H103.401C103.157 12.2589 102.737 11.6359 102.137 11.2124C101.539 10.7888 100.84 10.5781 100.042 10.5781C99.245 10.5781 98.5462 10.8 97.8826 11.2459V11.2436Z" fill="#481F6B"/>
    <path d="M148.678 22.8708H143.359V21.6C142.008 22.7654 140.28 23.3481 138.174 23.3481C135.914 23.3481 133.993 22.5592 132.408 20.9815C130.822 19.4037 130.032 17.3755 130.032 14.899C130.032 12.4226 130.825 10.419 132.408 8.83233C133.993 7.24337 135.914 6.45003 138.174 6.45003C140.28 6.45003 142.008 7.03273 143.359 8.19588V0H148.678V22.8708ZM142.147 17.8685C142.956 17.0953 143.359 16.1048 143.359 14.899C143.359 13.6933 142.956 12.7274 142.147 11.9453C141.338 11.1609 140.379 10.7709 139.272 10.7709C138.097 10.7709 137.133 11.1563 136.38 11.9295C135.628 12.7027 135.25 13.6933 135.25 14.899C135.25 16.1048 135.628 17.1223 136.38 17.8843C137.133 18.6463 138.097 19.0272 139.272 19.0272C140.379 19.0272 141.338 18.6395 142.147 17.8685Z" fill="#481F6B"/>
  </svg>
);
