import React, { useState, useEffect } from 'react';
import styles from './countdownTimer.module.scss';

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentDate = now.getDate();
    const midnight = new Date(Date.UTC(currentYear, currentMonth, currentDate + 1, 4, 0, 0)); // EDT is UTC-4
    const timeLeft = midnight - now;

    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    const seconds = Math.floor((timeLeft / 1000) % 60);

    return {
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.timer}>
      <span>Tomorrow&apos;s games ready in</span>
      <div className={styles.time}>
        <div className={styles.timeSegment}>
          <span>{timeLeft.hours} :</span>
          <div className={styles.label}>hrs</div>
        </div>
        <div className={styles.timeSegment}>
          <span>{timeLeft.minutes} :</span>
          <div className={styles.label}>mins</div>
        </div>
        <div className={styles.timeSegment}>
          <span>{timeLeft.seconds}</span>
          <div className={styles.label}>secs</div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
